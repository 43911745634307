import React, { useEffect, useState } from 'react';
import { store } from "../../store/index";
import { useSelector } from 'react-redux';
import { updateSelectedZone } from '../../actions';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import "./ZoneDropdown.css";
import { Trans } from 'react-i18next';

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

function ZoneDropdown() {
  const options = useSelector((state) => {
    // console.log('state.zones.available', state.zones.available)
    return state.zones.available && state.zones.available.length > 0 ? state.zones.available.map(zone => { return { value: zone.zone_id, label: zone.description } }) : []
  });
  const [selectedValue, setSelectedValue] = useState(storeState.zones.selected ? storeState.zones.selected.description : (options.length > 0 ? options[0].label : "No Zone Loaded"));

  useEffect(() => {
    setTimeout(() => {
      setSelectedValue(storeState.zones.selected.description)
    }, 500)
  })

  const handleChange = (option) => {
    setSelectedValue(option.label);
    store.dispatch(updateSelectedZone({ zoneId: option.value }));
    setTimeout(() => {
       window.location.reload();
    }, 500)
  };

  return (
    <>
      <NavDropdown
        title={
          <div>
            Zone: {selectedValue}
            <p className="hidden-md hidden-lg">
              <Trans>ZONE</Trans>
              <b className="caret" />
            </p>
          </div>
        }
        noCaret
        id="basic-nav-dropdown-2"
      >
        
        {options.map((option) => (
          <MenuItem onClick={() => handleChange(option)}>{option.label}</MenuItem>
        ))}
      </NavDropdown>
    </>
  );
}

export default ZoneDropdown;
