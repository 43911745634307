import {
    Col,
    Grid,
    Row
} from "react-bootstrap";
import React, { Component } from "react";

import DriversOnlineMap from "../Maps/DriversOnlineMap";
import { FormControl } from "react-bootstrap";
import { getOnlineDrivers } from "../../helpers/apiHelper";
import i18n from "i18next";

class DriversOnline extends Component {

    state = {
        onlineDrivers: [],
        driverFilter: ""
    }

    async componentDidMount() {
      const onlineDrivers = await getOnlineDrivers();
      console.log(onlineDrivers);
      await this.setState({onlineDrivers});

      this.task = setInterval(async () => {
        const onlineDrivers = await getOnlineDrivers();
        await this.setState({onlineDrivers});
      }, 6000);
    }

    componentWillUnmount() {
      clearInterval(this.task);
    }
  
    render() {
        return (
          <div
            className="card"
            style={{
              paddingTop: "60px",
              paddingBottom: "60px",
              marginBottom: "60px",
              textAlign: "center"
            }}
          >
            <Grid>
            <Row style={{ padding: "2%"}}>
              <Col md={12}>
              <FormControl
               style={{margin: 'auto', width: '50%', textAlign: 'center'}}
                id="driver"
                value={this.state.driverFilter}
                name="driver"
                onChange={(e) => this.setState({ driverFilter: e.target.value })}
                placeholder={i18n.t("FIND_DRIVER")}
              />
              </Col>
            </Row>
              <Row>
                <Col md={12}>
                  <DriversOnlineMap
                    drivers={this.state.onlineDrivers}
                    driverFilter={this.state.driverFilter}
                    history={this.props.history}
                  />
                </Col>
              </Row>
            </Grid>
          </div>);
    }
    
}

export default DriversOnline;
