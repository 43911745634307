enum CurrencyType {
  WholeNumber,
  Decimal
}

interface Currency {
  code: string;
  type: CurrencyType;
}

/**
 * Provides utility methods for formatting currency values.
 */
class CurrencyFormatter {

  private static defaultCurrencyCode: string = 'CAD';

  private static currencies: { [key: string]: Currency } = {
    CAD: { code: 'CAD', type: CurrencyType.Decimal },
    USD: { code: 'USD', type: CurrencyType.Decimal },
    EUR: { code: 'EUR', type: CurrencyType.Decimal },
    XOF: { code: 'XOF', type: CurrencyType.WholeNumber },
    CLP: { code: 'CLP', type: CurrencyType.WholeNumber },
  }

  /**
   * Formats a whole amount into a localized number format (example 3.20$ to 320¢)
   * @param amount - The amount to format.
   * @param currencyCode - Optional currency code to use for formatting. If not provided, the default currency code will be used.
   * @returns The amount number.
   */
  static convertToUnit(amount: number, currencyCode: string | null = null): number {
    const code = currencyCode ? currencyCode : CurrencyFormatter.defaultCurrencyCode;
    const currency = CurrencyFormatter.currencies[code];

    if (!currency) {
      throw new Error(`Unsupported currency: ${code}`);
    }

    let formattedAmount = amount;

    if (currency.type === CurrencyType.Decimal) {
      formattedAmount = Number((amount / 100).toFixed(2));
    } else {
      formattedAmount = Math.round(amount);
    }

    return formattedAmount;
  }

  /**
   * Formats an amount from a localized number format to a whole unit number (example 320¢ to 3.20$)
   * @param amount - The amount to format.
   * @param currencyCode - Optional currency code to use for formatting. If not provided, the default currency code will be used.
   * @returns The amount number.
   */
  static convertFromUnit(amount: number, currencyCode: string | null = null): number {
    const code = currencyCode ? currencyCode : CurrencyFormatter.defaultCurrencyCode;
    const currency = CurrencyFormatter.currencies[code];

    if (!currency) {
      throw new Error(`Unsupported currency: ${code}`);
    }

    let formattedAmount = amount;

    if (currency.type === CurrencyType.Decimal) {
      formattedAmount = amount * 100;
    }

    return Math.round(formattedAmount);
  }

  /**
   * Formats an amount into a localized currency string.
   * @param amount - The amount to format.
   * @param currencyCode - Optional currency code to use for formatting. If not provided, the default currency code will be used.
   * @returns The formatted currency string.
   */
  static formatAmount(amount: number, currencyCode: string | null = null): string {
    const code = currencyCode ? currencyCode : CurrencyFormatter.defaultCurrencyCode;
    const formattedAmount = CurrencyFormatter.convertToUnit(amount, code);

    return new Intl.NumberFormat("fr-CA", {
      style: 'currency',
      currency: code,
    }).format(formattedAmount);
  }

  /**
   * Convers an amount into CAD based on exchange rate.
   * @param amount - The amount to format.
   * @param currencyCode - Source currency code to convert from.
   * @param exchangeRate - Source currency rate to CAD.
   * @returns The formatted currency string.
   */
   static convertToCAD(amount: number, currencyCode: string, exchangeRate: number): string {
    const code = currencyCode ? currencyCode : CurrencyFormatter.defaultCurrencyCode;
    const formattedAmount = CurrencyFormatter.convertToUnit(amount, code);

    const convertedAmount = formattedAmount / exchangeRate;

    return new Intl.NumberFormat("fr-CA", {
      style: 'currency',
      currency: CurrencyFormatter.defaultCurrencyCode,
    }).format(convertedAmount);
  }
}

export { CurrencyFormatter };
