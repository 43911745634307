import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import React, { Component } from "react";

import FetchAuthStrategy from "./strategies/FetchAuthStrategy"
import FetchCommunityStrategy from "./strategies/FetchCommunityStrategy";
import FetchTokenTransferStrategy from "./strategies/FetchTokenTransferStrategy";
import FetchExchangeRatesStrategy from "./strategies/FetchExchangeRatesStrategy";
import LoginPage from "./views/login/LoginPage";
import { connect } from "react-redux";
import indexRoutes from "./routes/index.jsx";

class AppComponent extends Component {
  render() {
    let mainComponents = [];
    if (this.props.isAuthenticated) {
      mainComponents = [
        <FetchTokenTransferStrategy />,
        <FetchCommunityStrategy />,
        // <FetchExchangeRatesStrategy />,
        <HashRouter>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </HashRouter>
      ];
    }else {
      mainComponents = [<LoginPage />];
    }
    // mainComponents.push(<FetchAuthStrategy />);
    console.log("this.props.isAuthenticated", this.props.isAuthenticated);
    return mainComponents;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.userData.isAuthenticated
  };
};
export default connect(mapStateToProps)(AppComponent);
